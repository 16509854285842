import type { Ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import {
  computed,
  ref,
  watch,
} from 'vue';
import {
  useRouter,
} from 'vue-router';

import { TopWinnersTabType } from '@leon-hub/api-sdk';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from '@core/i18n';

import type { SwitcherOption } from 'web/src/components/Switcher/VSwitcher/types';
import type { LobbyAllTopWinnersSectionProps } from 'web/src/modules/lobby/components/LobbyAllTopWinnersSection/types';
import type { LobbyAllTopWinnersTableRow } from 'web/src/modules/lobby/components/LobbyAllTopWinnersTable/types';

export interface LobbyAllTopWinnersSectionComposable {
  switcherOptions: Ref<SwitcherOption[]>;
  items: Ref<LobbyAllTopWinnersTableRow[]>;
  isEgsTab: Ref<boolean>;
  allWinnersHref: Ref<string>;
  setActiveTab(tab: TopWinnersTabType): void;
  onAllClick(): void;
}

export default function useLobbyAllTopWinnersSection(props: LobbyAllTopWinnersSectionProps): LobbyAllTopWinnersSectionComposable {
  const router = useRouter();
  const { $translate } = useI18n();

  const allOptions = computed<SwitcherOption[]>(() => {
    const options: SwitcherOption[] = [];

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED) {
      options.push({
        id: TopWinnersTabType.SPORT,
        label: $translate('WEB2_ALL_WINNERS_TAB_SPORT').value,
      });
    }

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      options.push({
        id: TopWinnersTabType.EGS,
        label: $translate('WEB2_ALL_WINNERS_TAB_CASINO').value,
      });
    }

    return options;
  });

  const activeTab = ref(TopWinnersTabType.SPORT);

  watch(() => props.tab, (newValue) => {
    if (newValue) {
      activeTab.value = newValue;
    }
  }, {
    immediate: true,
  });

  const isEgsTab = computed(() => activeTab.value === TopWinnersTabType.EGS);

  function setActiveTab(tab: TopWinnersTabType): void {
    activeTab.value = tab;
  }

  const switcherOptions = computed<SwitcherOption[]>(() => (
    props.tab !== TopWinnersTabType.EGS ? allOptions.value : allOptions.value.toReversed()
  ));

  const allWinnersLocation = computed<RouteLocationNamedRaw>(() => {
    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && isEgsTab.value) {
      return {
        name: CasinoRouteName.CASINO_LOBBY,
      };
    }

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED) {
      return {
        name: RouteName.SPORTLINE_TOP_EVENTS,
      };
    }

    return {
      name: RouteName.HOME,
    };
  });

  const allWinnersHref = computed<string>(() => router.resolve(allWinnersLocation.value).href);

  function onAllClick(): void {
    void router.push(allWinnersLocation.value);
  }

  const items = computed(() => (
    isEgsTab.value ? props.topGames : props.topSports
  ));

  return {
    switcherOptions,
    items,
    isEgsTab,
    allWinnersHref,
    setActiveTab,
    onAllClick,
  };
}
