import type { Router } from 'vue-router';

import { LobbyItemType } from '@leon-hub/api-sdk';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

import type { LobbyItemUnit } from 'web/src/modules/lobby/types';
import { getLobbyLocation } from 'web/src/modules/egs/utils';

export function getLobbyActionHref(
  router: Router,
  lobbyItem: LobbyItemUnit,
): string | undefined {
  if (!process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    return router.resolve({
      name: RouteName.HOME,
    }).href;
  }

  if (lobbyItem.redirectPath) {
    return router.resolve(lobbyItem.redirectPath).href;
  }

  switch (lobbyItem.typeId) {
    case LobbyItemType.PROMOTIONS:
      return lobbyItem.url;
    case LobbyItemType.SPORTLINE_TOP_MATCHES:
      return router.resolve({
        name: RouteName.SPORTLINE_TOP_EVENTS,
      }).href;
    case LobbyItemType.BANNERS:
      return router.resolve({
        name: RouteName.PROMOTIONS,
      }).href;
    case LobbyItemType.GROUPS:
      return router.resolve({
        name: CasinoRouteName.CASINO_GROUPS,
      }).href;
    case LobbyItemType.GAMES_CATEGORY:
    case LobbyItemType.TOP_WINNERS:
      return router.resolve(getLobbyLocation(
        router.currentRoute.value,
        router.currentRoute.value.params.groupUrl as string,
        lobbyItem.category.url,
        lobbyItem.category.type,
      )).href;
    default:
      return undefined;
  }
}
