import type {
  ComputedRef,
  Ref,
} from 'vue';
import {
  computed,
  nextTick,
  ref,
  toRef,
  watch,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';

import { EgsTopWinnersFilter, LobbyItemType } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import {
  assert,
  isEnumOfType,
} from '@leon-hub/guards';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';
import {
  onComponentActivated,
} from '@leon-hub/vue-utils';

import { useIsLoggedIn } from '@core/auth';
import { useSiteConfigStore } from '@core/site-config';

import type { VSwiperScrollEvent } from 'web/src/components/Swiper/VSwiper/types';
import type { GameStartMethod } from 'web/src/modules/egs/submodules/navigation/enums';
import type {
  EgsCategory,
  EgsGame,
} from 'web/src/modules/egs/types';
import type {
  LobbyBlockEmits,
  LobbyBlockProps,
} from 'web/src/modules/lobby/components/LobbyBlock/types';
import type {
  LobbySectionContainerProps,
  LobbySectionContainerRef,
} from 'web/src/modules/lobby/components/LobbySectionContainer/types';
import type {
  LobbyItemClickData,
  LobbyItemUnit,
  LobbyTopWinnersItem,
} from 'web/src/modules/lobby/types';
import useEgsNavigation from 'web/src/modules/egs/submodules/navigation/composable/useEgsNavigation';
import {
  isLiveEgsRoute,
  navigateToCategoryPage,
  navigateToLobbyPage,
} from 'web/src/modules/egs/utils';
import {
  getLobbyItems,
  getLobbySectionProps,
  getSkeletonItems,
} from 'web/src/modules/lobby/components/LobbyBlock/composables/utils';
import {
  useLobbyStore,
} from 'web/src/modules/lobby/store';
import {
  isEgsBannerItem,
  isEgsGame,
  isEgsGroup,
  isEgsTopWinner,
  isLobbyGamesItem,
  isLobbyTopWinnersItem,
  isPromotion,
  isSportlineTopMatchItem,
  isSwiperHasToGetNewItems,
} from 'web/src/modules/lobby/utils';

export interface LobbyBlockComposable {
  isLoadingTransition: Ref<boolean>;
  isLoadingLobby: ComputedRef<boolean>;
  isContentLoading: Ref<boolean>;
  skeletonItems: ComputedRef<LobbySectionContainerProps[] | undefined>;
  lobbySectionProps: ComputedRef<LobbySectionContainerProps[]>;
  lobbyItems: Ref<LobbyItemUnit[]>;
  onSwiperScroll(event: VSwiperScrollEvent, lobbyItem?: LobbyItemUnit): void;
  onSectionScroll(event: DOMRect, lobbyItem?: LobbyItemUnit): void;
  onTopWinnersFilterClick(filter: string, lobbyItem?: LobbyItemUnit): void;
  onClickLobbyItem(data: LobbyItemClickData, lobbyItem?: LobbyItemUnit): void;
  onActionClick(lobbyItem?: LobbyItemUnit): void;
  emitDeleteItems(ids: string[]): void;
}

export default function useLobbyBlock(
  props: LobbyBlockProps,
  emit: LobbyBlockEmits,
): LobbyBlockComposable {
  const { isLoggedIn } = useIsLoggedIn();
  const router = useRouter();
  const route = useRoute();
  const bus = useEventsBus();
  const lobbyStore = useLobbyStore();
  const { navigateToGamePage, isDemo } = useEgsNavigation(router);
  const filteredGroupId = computed(() => (props.groupBlock ? undefined : props.groupId));

  const items = computed(() => lobbyStore.getLobbyItems(props.lobbyType, filteredGroupId.value, props.categoryId).value);
  const lobbyItems = computed(() => getLobbyItems(props, items.value));

  const externalGamesConfig = toRef(useSiteConfigStore(), 'externalGames');

  const container = ref<LobbySectionContainerRef[]>();

  const isLoadingLobby = computed(() => lobbyStore.getIsLoading(props.lobbyType, props.groupId, props.categoryId).value);

  const isLoadingPerf = ref(isLoadingLobby.value);
  const isLoadingTransition = ref(isLoadingLobby.value);
  const isContentLoading = computed(() => isLoadingLobby.value || isLoadingPerf.value);

  const skeletonItems = computed<LobbySectionContainerProps[]>(() => getSkeletonItems(props, isContentLoading.value));

  const lobbySectionProps = computed<LobbySectionContainerProps[]>(() => getLobbySectionProps(
    props,
    isContentLoading.value,
    lobbyItems.value,
    router,
    externalGamesConfig.value?.lazyLoadPagesCounter ?? 0,
  ));

  function loadItems(): void {
    void lobbyStore.loadLobbyItems({
      type: props.lobbyType,
      group: props.groupId,
      category: props.categoryId,
      excludeGameIds: props.excludeGameIds,
    });
  }

  function fetchMoreGames(lobbyItem: LobbyItemUnit): void {
    if (isLobbyGamesItem(lobbyItem)) {
      void lobbyStore.loadMoreLobbyItemGames({
        lobbyItem,
        groupId: props.groupId,
        isAllTab: props.isAllTab,
        excludeGameIds: props.excludeGameIds,
      });
    }

    if (isLobbyTopWinnersItem(lobbyItem)) {
      void lobbyStore.loadLobbyItemTopWinners({
        lobbyItem,
      });
    }
  }

  function onSwiperScroll(event: VSwiperScrollEvent, lobbyItem?: LobbyItemUnit): void {
    if (!lobbyItem) {
      return;
    }

    if (isSwiperHasToGetNewItems(event)) {
      fetchMoreGames(lobbyItem);
    }
  }

  function onSectionScroll(event: DOMRect, lobbyItem?: LobbyItemUnit): void {
    if (!lobbyItem) {
      return;
    }

    const clientHeight = window.innerHeight || document.documentElement.clientHeight;
    if (2 * clientHeight >= event.height + event.top) {
      fetchMoreGames(lobbyItem);
    }
  }

  function onTopWinnersFilterClick(filter: string, lobbyItem?: LobbyTopWinnersItem): void {
    assert(isEnumOfType(EgsTopWinnersFilter)(filter));
    if (!lobbyItem || lobbyItem.filter === filter) {
      return;
    }

    void lobbyStore.loadLobbyItemTopWinners({ lobbyItem, filter, isReplace: true });
  }

  function onSelectCategory(category: EgsCategory): void {
    navigateToCategoryPage(router, route, category, props.groupUrl);
  }

  function onChangeRoute(href: string): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const normalizedUrl = href.startsWith('#') ? href.slice(1) : href;
      void router.push(normalizedUrl);
      return;
    }
    void router.push(href);
  }

  function onActionClick(lobbyItem?: LobbyItemUnit): void {
    if (!process.env.VUE_APP_FEATURE_CASINO_ENABLED || !lobbyItem) {
      return;
    }

    const scrollTop = (
      route.name === CasinoRouteName.CASINO_LIVE_GROUPS
      || route.name === CasinoRouteName.CASINO_LOBBY
    ) && (
      lobbyItem.typeId === LobbyItemType.GAMES_CATEGORY
      || lobbyItem.typeId === LobbyItemType.TOP_WINNERS
    );

    if (scrollTop) {
      bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
    }

    if (lobbyItem.redirectPath) {
      onChangeRoute(lobbyItem.redirectPath);
      return;
    }

    switch (lobbyItem.typeId) {
      case LobbyItemType.PROMOTIONS:
        onChangeRoute(lobbyItem.url);
        break;
      case LobbyItemType.TOP_WINNERS:
      case LobbyItemType.GAMES_CATEGORY:
        onSelectCategory(lobbyItem.category);
        break;
      case LobbyItemType.GROUPS:
        void router.push({
          name: isLiveEgsRoute(route) ? CasinoRouteName.CASINO_LIVE_GROUPS : CasinoRouteName.CASINO_GROUPS,
        });
        break;
      case LobbyItemType.BANNERS:
        void router.push({
          name: RouteName.PROMOTIONS,
        });
        break;
      case LobbyItemType.SPORTLINE_TOP_MATCHES:
        void router.push({
          name: RouteName.SPORTLINE_TOP_EVENTS,
        });
        break;
      default:
        break;
    }
  }

  function onPlay(lobbyItem: LobbyItemUnit, game: EgsGame, selectedMethod?: GameStartMethod): void {
    void navigateToGamePage(game, selectedMethod, props.shouldReplaceGameRoutes);
    emit('game-click', game, isDemo(game, selectedMethod), lobbyItem);
  }

  function onClickLobbyItem(data: LobbyItemClickData, lobbyItem?: LobbyItemUnit): void {
    if (!lobbyItem) {
      return;
    }

    switch (lobbyItem.typeId) {
      case LobbyItemType.PROMOTIONS:
        assert(isPromotion(data.item, lobbyItem.typeId));
        onChangeRoute(data.item.url);
        break;
      case LobbyItemType.TOP_WINNERS:
        assert(isEgsTopWinner(data.item, lobbyItem.typeId));
        onPlay(lobbyItem, data.item.egsGame, data.selectedMethod);
        break;
      case LobbyItemType.GAMES_CATEGORY:
        assert(isEgsGame(data.item, lobbyItem.typeId));
        onPlay(lobbyItem, data.item, data.selectedMethod);
        break;
      case LobbyItemType.BANNERS:
        assert(isEgsBannerItem(data.item, lobbyItem.typeId));
        onChangeRoute(data.item.url);
        break;
      case LobbyItemType.SPORTLINE_TOP_MATCHES:
        assert(isSportlineTopMatchItem(data.item, lobbyItem.typeId));
        void router.push({
          name: RouteName.SPORT_EVENT_DETAILS,
          params: { ...data.item.navigationParameters },
        });
        break;
      case LobbyItemType.GROUPS:
        assert(isEgsGroup(data.item, lobbyItem.typeId));
        useEventsBus().emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
        navigateToLobbyPage(router, route, data.item.url);
        break;
      default:
        break;
    }
  }

  function emitDeleteItems(ids: string[]): void {
    emit('delete-items', ids);
  }

  onComponentActivated(() => {
    if (!props.preventItemsFetch) {
      loadItems();
    }
  });

  watch(isLoadingLobby, async (newValue) => {
    if (newValue) {
      isLoadingTransition.value = true;
      isLoadingPerf.value = true;

      // Wait 1 frame to render transition
      await nextTick();
      await nextAnimationFrame();
      isLoadingTransition.value = false;

      // Wait 2 frames to render skeleton
      await nextTick();
      await nextAnimationFrame();
      await nextAnimationFrame();
      isLoadingPerf.value = false;
    }
  }, {
    immediate: true,
  });

  watch(isContentLoading, () => {
    if (container.value) {
      for (const section of container.value) {
        section.resetScrollPosition();
      }
    }
  });

  watch(isLoggedIn, () => {
    loadItems();
  });

  return {
    isLoadingTransition,
    isLoadingLobby,
    isContentLoading,
    skeletonItems,
    lobbySectionProps,
    lobbyItems,
    onSwiperScroll,
    onSectionScroll,
    onTopWinnersFilterClick,
    onClickLobbyItem,
    onActionClick,
    emitDeleteItems,
  };
}
