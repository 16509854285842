import { defineStore } from 'pinia';

import type {
  Web2Money,
} from '@leon-hub/api-sdk';

import type { LobbyItemUnit } from 'web/src/modules/lobby/types';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
import { getJackpotGameIdsFromLobbyItems } from 'web/src/modules/lobby/store/utils';
import {
  isLobbyGamesItem,
} from 'web/src/modules/lobby/utils';

const useLobbyJackpotsStore = defineStore('lobby-jackpots', () => {
  const gamesStore = useEgsGamesStore();
  const { getGames } = useEgsApi();

  async function updateLobbyJackpotGamesAmount(lobbyItems: LobbyItemUnit[]): Promise<void> {
    const gameIds = getJackpotGameIdsFromLobbyItems(lobbyItems);

    if (gameIds.length > 0) {
      const data = await getGames({
        gameIds,
      }, true);

      const gamesRecords = data.games.reduce<Record<string, Web2Money | undefined>>((accumulator, game) => {
        accumulator[game.id] = game.jackpot?.amount;

        return accumulator;
      }, {});

      gamesStore.updateGames(data.games);

      for (const lobbyItem of lobbyItems) {
        if (isLobbyGamesItem(lobbyItem)) {
          lobbyItem.games = lobbyItem.games.map((game) => {
            const { id, jackpot } = game;
            const newValue = gamesRecords[id];

            if (!jackpot || !newValue) {
              return game;
            }

            return {
              ...game,
              jackpot: {
                amount: {
                  ...jackpot.amount,
                  ...newValue,
                },
              },
            };
          });
        }
      }
    }
  }

  return {
    updateLobbyJackpotGamesAmount,
  };
});

export default useLobbyJackpotsStore;
