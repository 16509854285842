<script setup lang="ts">
import { Tag } from '@leon-hub/tags';

import {
  ButtonHeight,
  ButtonKind,
  VButton,
} from '@components/buttons';

import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import LobbyAllTopWinnersTable from 'web/src/modules/lobby/components/LobbyAllTopWinnersTable/LobbyAllTopWinnersTable.vue';
import LobbySectionHeader from 'web/src/modules/lobby/components/LobbySectionHeader/LobbySectionHeader.vue';

import type { LobbyAllTopWinnersSectionProps } from './types';
import { useLobbyAllTopWinnersSection } from './composables';

const props = defineProps<LobbyAllTopWinnersSectionProps>();
const {
  switcherOptions,
  items,
  isEgsTab,
  allWinnersHref,
  setActiveTab,
  onAllClick,
} = useLobbyAllTopWinnersSection(props);
</script>

<template>
  <div v-auto-id="'LobbyAllTopWinnersSection'"
    :class="$style['lobby-all-top-winners']"
  >
    <LobbySectionHeader
      :title="headerTitle"
      :icon-url="headerIcon"
      :is-custom-homepage="isCustomHomepage"
    >
      <template #filters>
        <VSwitcher
          :options="switcherOptions"
          :class="$style['lobby-all-top-winners__switcher']"
          @change="setActiveTab"
        />
      </template>
    </LobbySectionHeader>
    <div
      :class="$style['lobby-all-top-winners__table-wrapper']"
    >
      <LobbyAllTopWinnersTable
        :items="items"
        :is-egs="isEgsTab"
      />
      <VButton
        :label="isEgsTab ? $t('WEB2_ALL_WINNERS_TABLE_ALL_WINNERS_EGS') : $t('WEB2_ALL_WINNERS_TABLE_ALL_WINNERS')"
        full-width
        :height="ButtonHeight.SMALL"
        :kind="ButtonKind.BASE"
        :class="$style['lobby-all-top-winners__btn']"
        :tag="Tag.A"
        :href="allWinnersHref"
        @click.prevent="onAllClick"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using($isDesktop) {
  .lobby-all-top-winners {
    display: flex;
    flex-direction: column;

    &__switcher {
      @if $isDesktop {
        min-width: 343px;
        margin-left: auto;
      } @else {
        width: 100%;
        margin: 16px 8px 8px;
      }
    }

    &__table-wrapper {
      width: 100%;

      @if not $isDesktop {
        padding: 0 8px;
      }
    }

    &__btn {
      margin-top: 8px;
    }
  }
}
</style>
