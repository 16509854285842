import { defineStore } from 'pinia';
import {
  toRef,
} from 'vue';

import type { LobbyItemUnit } from 'web/src/modules/lobby/types';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  CoreSportEventResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  isGetHeadlineMatchesChangesResponseCheck,
  isGetMergedHeadlineMatchesChangesResponseCheck,
  isGetMergedHeadlineMatchesResponseCheck,
} from 'web/src/modules/sportline/guards/rest';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import {
  filterCorruptedEvents,
  logCorruptedEventsIds,
} from 'web/src/modules/sportline/utils/response';
import { DetailsAdapter } from 'web/src/modules/sportline/utils/rest';

import {
  getLobbyTopMatchesItems,
} from './utils';

const useLobbySportlineUpdateStore = defineStore('lobby-sportline-update', () => {
  const settingsStore = useSportlineSettingsStore();
  const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');

  const apiService = useSportlineApiService();

  async function updateLobbySportlineTopMatches(lobbyItems: LobbyItemUnit[]): Promise<void> {
    const sportlineTopMatchesItems = getLobbyTopMatchesItems(lobbyItems);

    if (sportlineTopMatchesItems.length > 0) {
      const result = await apiService.loadHeadlineMatches({
        allVTag: sportlineTopMatchesItems[0].vtag,
        silent: true,
        doForceMergeLists: true,
        doForceDisableLiveOutrights: true,
      });

      let newVtag = '';
      const newEvents: SportEventDetails[] = [];
      let responseEvents: CoreSportEventResponse[] = [];
      let doUpdate = false;

      if (result
        && isGetHeadlineMatchesChangesResponseCheck(result)
        && isGetMergedHeadlineMatchesChangesResponseCheck(result)
      ) {
        newVtag = result.events.vtag || '';
        responseEvents = result.events.data;
        doUpdate = true;
      }

      if (result
        && !isGetHeadlineMatchesChangesResponseCheck(result)
        && isGetMergedHeadlineMatchesResponseCheck(result)
      ) {
        newVtag = result.events.vtag || '';
        responseEvents = result.events.events;
        doUpdate = true;
      }

      if (doUpdate) {
        for (const event of filterCorruptedEvents(responseEvents, logCorruptedEventsIds)) {
          newEvents.push(
            (new DetailsAdapter(event, parseSportlineSettings.value))
              .sportEventDetails,
          );
        }

        for (const lobbyItem of sportlineTopMatchesItems) {
          lobbyItem.events = newEvents;
          lobbyItem.vtag = newVtag;
        }
      }
    }
  }

  return {
    updateLobbySportlineTopMatches,
  };
});

export default useLobbySportlineUpdateStore;
