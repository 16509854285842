<script lang="ts" setup>
import type { LobbyGridEmits, LobbyGridProps } from 'web/src/modules/lobby/components/LobbyGrid/types';
import useLobbyGrid from 'web/src/modules/lobby/components/composables/useLobbyGrid';
import LobbyItem from 'web/src/modules/lobby/components/LobbyItem/LobbyItem.vue';

const props = defineProps<LobbyGridProps>();
const emits = defineEmits<LobbyGridEmits>();

const {
  getItemKey,
  gridType,
  getLobbyItemProps,
  emitClickLobbyItem,
} = useLobbyGrid(props, emits);
</script>

<template>
  <div v-auto-id="'LobbyGrid'"
    :class="{
      [$style['lobby-grid']]: true,
      [$style[`lobby-grid--type-${gridType}`]]: true,
    }"
  >
    <LobbyItem
      v-for="(item, index) in items"
      :key="getItemKey(item, index)"
      v-bind="getLobbyItemProps(item, index)"
      :class="$style['lobby-grid__item']"
      @click-lobby-item="emitClickLobbyItem"
    />
    <LobbyItem
      v-if="'1' && hasNext && !isLoadMoreButtonVisible"
      v-bind="getLobbyItemProps(undefined, 0)"
      :class="$style['lobby-grid__item']"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .lobby-grid {
    display: grid;
    grid-gap: $lobbyGridDefaultGap;

    &--type {
      &-big-small-large {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px 24px;

        .lobby-grid__item {
          grid-column: span 2;

          &:nth-child(n + 3) {
            grid-column: span 1;
          }
        }
      }

      &-big-small-small {
        grid-template-columns: repeat(2, 1fr);

        .lobby-grid__item {
          &:nth-child(3n + 1) {
            grid-column: span 2;
          }

          &:nth-child(3n + 4) {
            margin-top: -4px;
          }
        }
      }

      &-square-row {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 20px 24px;
        }
      }

      &-column-1 {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 8px;
      }

      &-column-banner {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 16px;
      }

      &-column-2 {
        grid-template-columns: repeat(2, 1fr);
      }

      &-column-3 {
        grid-template-columns: repeat(3, 1fr);
      }

      &-column-4 {
        grid-template-columns: repeat(4, 1fr);
      }

      &-column-game-small {
        grid-template-columns: repeat(4, 1fr);

        @if $isDesktop {
          grid-gap: 24px 16px;
        }

        @media screen and (min-width: 500px) {
          grid-template-columns: repeat(5, 1fr);
        }

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(6, 1fr);
        }

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(8, 1fr);
        }

        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(10, 1fr);
        }
      }

      &-column-game-default {
        @include lobby-grid-game-default;
      }

      &-column-game-large {
        grid-template-columns: repeat(2, 1fr);

        @if $isDesktop {
          grid-gap: 24px 16px;
        }

        @media screen and (min-width: 500px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(6, 1fr);
        }
      }

      &-column-square-game-small {
        grid-template-columns: repeat(4, 1fr);

        @if $isDesktop {
          grid-gap: 24px 16px;
        }

        @media screen and (min-width: 500px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(6, 1fr);
        }

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(6, 1fr);
        }

        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(8, 1fr);
        }
      }

      &-column-square-game-default {
        grid-template-columns: repeat(2, 1fr);

        @if $isDesktop {
          grid-gap: 24px 16px;
        }

        @media screen and (min-width: 500px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(6, 1fr);
        }
      }

      &-column-square-game-large {
        grid-template-columns: repeat(1, 1fr);

        @if $isDesktop {
          grid-gap: 24px 16px;
        }

        @media screen and (min-width: 500px) {
          grid-template-columns: repeat(1, 1fr);
        }

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      &-column-promotion,
      &-column-jackpot,
      &-column-top-winner {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 8px;

        @media screen and (min-width: 500px) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 16px;
        }

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      &-column-sportline-top-match {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 8px;

        @media screen and (min-width: 500px) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 16px;
        }

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      &-column-jackpot {
        @if $isDesktop {
          grid-gap: 24px 16px;
        }
      }

      &-column-group {
        grid-template-columns: repeat(2, 1fr);

        @media screen and (min-width: 500px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (min-width: 600px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (min-width: 800px) {
          grid-template-columns: repeat(6, 1fr);
        }

        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(8, 1fr);
        }
      }
    }
  }
}
</style>
