import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  nextTick,
  onActivated,
  onMounted,
  ref,
} from 'vue';

import { EgsTopWinnersFilter } from '@leon-hub/api-sdk';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';

import { useI18n } from '@core/i18n';

import type { TabsItem } from 'web/src/components/Tabs/types';
import type { VTabsProps } from 'web/src/components/Tabs/VTabs/types';
import type { LobbySectionHeaderProps } from 'web/src/modules/lobby/components/LobbySectionHeader/types';
import type {
  LobbyGridBaseProps,
  LobbyItemClickData,
  LobbySectionBaseEmits,
  LobbySectionBaseProps,
} from 'web/src/modules/lobby/types';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { useLobbySection } from 'web/src/modules/lobby/components/composables/useLobbySection';

export interface LobbyGridSectionComposable {
  wrapper: Ref<HTMLDivElement | undefined>;
  lobbyGridBaseProps: ComputedRef<LobbyGridBaseProps>;
  headerProps: ComputedRef<LobbySectionHeaderProps>;
  isSmallHeader: ComputedRef<boolean>;
  isEdit: Ref<boolean>;
  isHeaderVisible: ComputedRef<boolean>;
  isEditBtnEnabled: ComputedRef<boolean | undefined>;
  tabs: ComputedRef<TabsItem[]>;
  noGamesDesc: ComputedRef<string | undefined>;
  noGamesTitle: ComputedRef<string | undefined>;
  emitActionClick(): void;
  onEditClick(): void;
  tabsProps: ComputedRef<VTabsProps>;
  emitScrollTop(): void;
  emitTopWinnersFilterClick(value: string): void;
  onClickLobbyItem(data: LobbyItemClickData): void;
  totalText: Ref<string>;
  totalCounter: Ref<number | undefined>;
  currentCounter: Ref<number>;
}

export default function useLobbyGridSection(
  props: LobbySectionBaseProps,
  emits: LobbySectionBaseEmits,
): LobbyGridSectionComposable {
  const {
    lobbyGridBaseProps,
    isEdit,
    headerProps,
    isSmallHeader,
    isEditBtnEnabled,
    isHeaderVisible,
    emitActionClick,
    onEditClick,
    onClickLobbyItem,
  } = useLobbySection(props, emits);

  const { $translate } = useI18n();
  const wrapper = ref<HTMLDivElement>();

  const tabs = computed<TabsItem[]>(() => [
    { id: EgsTopWinnersFilter.DAY, label: $translate('WEB2_DATEPICKER_DAY').value },
    { id: EgsTopWinnersFilter.WEEK, label: $translate('WEB2_DATEPICKER_WEEK').value },
    { id: EgsTopWinnersFilter.MONTH, label: $translate('WEB2_DATEPICKER_MONTH').value },
    { id: EgsTopWinnersFilter.ALL_TIME, label: $translate('WEB2_DATEPICKER_ALL_TIME').value },
  ]);

  const tabsProps = computed<VTabsProps>(() => ({
    items: tabs.value,
    activeId: props.topWinnersFilter,
    type: TabsType.NORMAL,
  }));

  const noGamesTitle = computed(() => {
    if (props.isEditable) {
      return $translate('WEB2_EGS_MY_GAMES_EMPTY_TTL').value;
    }

    if (props.topWinnersFilter) {
      return $translate('WEB2_EGS_TOP_WINNERS_NO_RESULT_TITLE').value;
    }

    return undefined;
  });

  const noGamesDesc = computed(() => {
    if (props.isEditable) {
      return $translate('WEB2_EGS_MY_GAMES_EMPTY_DESC').value;
    }

    return undefined;
  });

  onMounted(scrollOnMounted);
  onActivated(scrollOnMounted);

  async function scrollOnMounted(): Promise<void> {
    if (!wrapper.value) {
      await nextTick();
    }

    if (process.env.VUE_APP_FEATURE_EGS_CATEGORY_GAMES_LAZY_LOADING_ENABLED || !props.isLoadMoreButtonVisible) {
      emitScrollTop();
    }
  }

  function onContentScroll(): void {
    if (process.env.VUE_APP_FEATURE_EGS_CATEGORY_GAMES_LAZY_LOADING_ENABLED || !props.isLoadMoreButtonVisible) {
      emitScrollTop();
    }
  }

  useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL, onContentScroll);

  function emitScrollTop(): void {
    if (wrapper.value) {
      emitSectionScroll(wrapper.value.getBoundingClientRect());
    }
  }

  function emitSectionScroll(event: DOMRect): void {
    emits('section-scroll', event);
  }

  function emitTopWinnersFilterClick(value: string): void {
    emits('top-winners-filter-click', value);
  }

  const currentCounter = computed<number>(() => props.lobbyItems.length);
  const totalCounter = computed<number | undefined>(() => (
    !props.hasNext ? currentCounter.value : props.total
  ));

  const totalTextPlaceholder = computed<Record<string, string>>(() => ({
    count: String(currentCounter.value),
    total: String(totalCounter.value),
  }));

  const firstPageTranslation = $translate('WEB2_EGS_PAGINATION_FIRST_PAGE_COUNTER', totalTextPlaceholder);
  const viewedPageTranslation = $translate('WEB2_EGS_PAGINATION_VIEWED_PAGE_COUNTER', totalTextPlaceholder);
  const reachedEndTranslation = $translate('WEB2_EGS_PAGINATION_REACHED_END');

  const totalText = computed(() => {
    if (!props.hasNext) {
      return reachedEndTranslation.value;
    }

    if (props.isFirstPage) {
      return firstPageTranslation.value;
    }

    return viewedPageTranslation.value;
  });

  return {
    isEdit,
    wrapper,
    lobbyGridBaseProps,
    headerProps,
    isSmallHeader,
    isEditBtnEnabled,
    isHeaderVisible,
    tabsProps,
    tabs,
    noGamesDesc,
    noGamesTitle,
    totalCounter,
    totalText,
    currentCounter,
    emitActionClick,
    onEditClick,
    emitTopWinnersFilterClick,
    onClickLobbyItem,
    emitScrollTop,
  };
}
