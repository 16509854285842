import type { Ref } from 'vue';
import {
  computed,
} from 'vue';

import { useComponentSyncState } from '@core/sync-state';

import type { LobbyItemUnit } from 'web/src/modules/lobby/types';
import {
  EgsJackpotsSyncStateTimerName,
  SportlineLobbySyncStateTimerName,
} from 'web/src/modules/egs/constants';
import useLobbyJackpotsStore from 'web/src/modules/lobby/store/useLobbyJackpotsStore';
import useLobbySportlineUpdateStore from 'web/src/modules/lobby/store/useLobbySportlineUpdateStore';
import {
  getJackpotGameIdsFromLobbyItems,
  getLobbyTopMatchesItems,
} from 'web/src/modules/lobby/store/utils';

export function useLobbySyncState(
  lobbyItems: Ref<LobbyItemUnit[]>,
): void {
  const { updateLobbyJackpotGamesAmount } = useLobbyJackpotsStore();
  const { updateLobbySportlineTopMatches } = useLobbySportlineUpdateStore();
  const hasJackpotsUpdatableItems = computed<boolean>(() => getJackpotGameIdsFromLobbyItems(lobbyItems.value).length > 0);
  const hasSportlineMatchesItems = computed<boolean>(() => getLobbyTopMatchesItems(lobbyItems.value).length > 0);

  useComponentSyncState(
    () => updateLobbyJackpotGamesAmount(lobbyItems.value),
    EgsJackpotsSyncStateTimerName,
    {
      condition: hasJackpotsUpdatableItems,
    },
  );

  useComponentSyncState(() => updateLobbySportlineTopMatches(lobbyItems.value), SportlineLobbySyncStateTimerName, {
    condition: hasSportlineMatchesItems,
  });
}
